import { defineStore } from "pinia";
import { exportMenu, fileMenu, helpMenu, menuItems, shareMenu, viewMenu } from "@/data/menu-items";
import { LoggedUserMenuItems, userMenuItems } from "@/data/user-menu-items";
import { FetchRequest } from "../helpers/fetch-request";
import { useStorage } from 'nvd-use-storage'
import { newTab } from "@/data/tab-template";
import { useAuthStore } from "@/stores/auth.store";
import { useTabsStore } from "@/stores/tabs.store";
import { useSchemaStore } from "@/stores/schema.store";
import { useSettingsStore } from "@/stores/settings.store";
import { newField, newTable } from "@/helpers/table-helper";
import { CreateTableCommand } from "@/commands/CreateTableCommand";
import { CreateNoteCommand } from "@/commands/CreateNoteCommand";
import { CanvasDownloader } from "@/modules/canvas/CanvasDownloader";
import router from "@/router/routes";
import { tabHelper } from "@/helpers/tabHelper";
import { cssVar } from "@/helpers/misc";
import { isMacOs } from "@/helpers/osHelper";
import { CRITICAL_FIELD_LIMIT, CRITICAL_TABLE_LIMIT } from "@/data/constants";
import { useNotify } from "nvd-u/composables/Notifiy";
import { useConfirm } from "nvd-u/composables/Confirm";
import i18n from "../locales/translations";
import { useVersionsStore } from "./versions.store";
import { env } from "@/env"
import { FileHelper } from "nvd-js-helpers/FileHelper";
import { useDownloadsStore } from "./downloads.store";

const notify = useNotify()


export const useMenuStore = defineStore('menu', {
    state: () => ({
        embedded: false,
        menu: menuItems,
        exportMenu: exportMenu,
        userMenu: userMenuItems,
        fileMenu: fileMenu,
        helpMenu: helpMenu,
        viewMenu: viewMenu,
        shareMenu: shareMenu,
        currentMenu: null,
        appIntroModalOpen: true,
        criticalTableLimitReachedModalOpen: false,
        CreateNewSchemaModalOpen: false,
        loginModal: false,
        loadSchemaModal: false,
        schemaPropertiesModal: false,
        indexModal: false,
        keyBoardShortcuts: false,
        exportSql: false,
        exportAi: false,
        generateSchemaReq: new FetchRequest('', 'POST'),
        exportWithAiReq: new FetchRequest('', 'POST'),
        exportImage: false,
        exportPdf: false,
        generatingSql: false,
        loadTemplatesModal: false,
        feedbackModal: false,
        importSqlModal: false,
        schemaSaveAsModal: false,
        documentationModal: false,
        shareModal: false,
        inviteUserReq: new FetchRequest('', 'POST'),
        getShortUrlReq: new FetchRequest('', 'POST'),
        getSchemaCollaboratorsReq: new FetchRequest('', 'GET'),
        deleteInvitedUserReq: new FetchRequest('', 'DELETE'),
        optionModal: false,
        embedModal: false,
        sharableLinkModal: false,
        lineTypeModal: false,
        presentation_view: useStorage('presentation_view'),
        exportAiLoading: false
    }),
    getters: {},
    actions: {
        // mutations
        toggleEmbedded(payload) {
            this.embedded = payload
        },
        toggleAppIntroModalOpen(payload) {
            this.appIntroModalOpen = payload
        },
        toggleCriticalTableLimitReachedModal(payload) {
            this.criticalTableLimitReachedModalOpen = payload
        },
        toggleCreateNewSchemaModalOpen(payload) {
            this.CreateNewSchemaModalOpen = payload
        },
        toggleLoginModal(payload) {
            this.loginModal = payload
        },
        toggleLoadSchemaModal(payload) {
            this.loadSchemaModal = payload
        },
        toggleSchemaPropertiesModal(payload) {
            this.schemaPropertiesModal = payload
        },
        toggleLoadTemplatesModal(payload) {
            this.loadTemplatesModal = payload
        },
        toggleImportSqlModal(payload) {
            this.importSqlModal = payload
        },
        toggleFeedbackModal(payload) {
            this.feedbackModal = payload
        },
        toggleUserMenuValues(payload) {
            this.userMenu = payload
        },
        setCurrentMenu(payload) {
            this.currentMenu = payload
        },
        toggleIndexModal(payload) {
            this.indexModal = payload
        },
        toggleDocumentationModal(payload) {
            this.documentationModal = payload
        },
        toggleKeyboardShortcutModal(payload) {
            this.keyBoardShortcuts = payload
        },
        toggleSchemaSaveAsModal(payload) {
            this.schemaSaveAsModal = payload
        },
        toggleLineTypeModal(payload) {
            this.lineTypeModal = payload
        },
        exportSqlModal(payload) {
            this.exportSql = payload
        },
        exportAiModal(payload) {
            this.exportAi = payload
        },
        toggleShareModal(payload) {
            this.shareModal = payload
        },
        toggleOptionModal(payload) {
            this.optionModal = payload
        },
        toggleEmbedModal(payload) {
            this.embedModal = payload
        },
        toggleSharableLinkModal(payload) {
            this.sharableLinkModal = payload
        },

        // actions
        async createNewSchema(form) {
            const auth = useAuthStore()
            const schema = useSchemaStore()
            if (auth.user) {
                return await schema.saveNewSchema(form)
            }
        },
        updateMenu() {
            let user = useAuthStore().user
            if (user) {
                this.toggleUserMenuValues(LoggedUserMenuItems())
            } else {
                this.toggleUserMenuValues(userMenuItems)
            }
        },
        createNewTable(table = {}) {
            const tabs = useTabsStore()
            let _table = newTable({
                ...table,
                fields: [{
                    ...newField,
                    name: 'id',
                    pk: true,
                    auto_increment: true,
                    unique: true,
                    allow_null: false,
                    order: 1
                }],
                $newTable: true,
                opened: !tabs.selectedTab?.schema?.schema_data?.tables?.length
            })
            tabs.selectedTab.canvas.setEditedTable(_table)
        },
        createNewNote(note = {}) {
            const tabs = useTabsStore()
            note = {
                content: "Double click to edit this note.",
                color: '#ffeb3a',
                ...note,
                $newNote: true,
                p_id: "new",
                resource: "note"
            }
            let command = new CreateNoteCommand(tabs.selectedTab, {note})
            command.execute()
            tabs.selectedTab.canvas.addNewItem(note)
        },
        login(payload) {
            const auth = useAuthStore()
            auth.login(payload)
            this.toggleLoginModal(false)
        },
        loadTemplates() {
            const schema = useSchemaStore()
            if (!schema.schemaTemplatesReq.loaded || !schema.schemaTemplatesReq.data?.length) {
                schema.schemaTemplatesReq.send()
            }
        },
        undo() {
            const tabs = useTabsStore()
            if (tabs.canUndo)
                tabs.undo()
        },
        redo() {
            const tabs = useTabsStore()
            if (tabs.canRedo)
                tabs.redo()
        },
        selectAll() {
            const tabs = useTabsStore()
            const tab = tabs.selectedTab
            tab.canvas.selectedItems.selectMany(tab.schema.schema_data.tables.concat(tab.schema.schema_data.notes))
        },
        selectNone() {
            const tabs = useTabsStore()
            const tab = tabs.selectedTab
            tab.canvas.selectedItems.clear()
        },
        showHistory() {
            const versions = useVersionsStore()
            const settings = useSettingsStore()
            versions.load()
            settings.settings.sidebar = true
            settings.modals.history = true
        },
        newSchema() {
            const auth = useAuthStore()
            const schema = useSchemaStore()
            const tabs = useTabsStore()
            if (auth.planLimitReached) {
                return schema.saveSchema()
            }
            if (!schema.schemaTemplatesReq.loaded) {
                schema.schemaTemplatesReq.send()
            }
            tabs.setShowNewTabConfirmationDialogue(true)
            this.toggleCreateNewSchemaModalOpen(true)
        },
        loadSchema() {
            const auth = useAuthStore()
            const schema = useSchemaStore()
            const tabs = useTabsStore()
            if (auth.planLimitReached) {
                return schema.saveSchema()
            }
            if (!schema.loadSchemasReq.loaded || !schema.loadSchemasReq.data?.own_schemas?.length) {
                schema.loadSchemasReq.send()
            }
            tabs.setShowNewTabConfirmationDialogue(true)
            this.toggleLoadSchemaModal(true)
        },
        schemaProperties() {
            this.toggleSchemaPropertiesModal(true)
        },
        loadTemplate() {
            const auth = useAuthStore()
            if (auth.planLimitReached) {
                return schema.saveSchema()
            }
            this.loadTemplates()
            this.toggleLoadTemplatesModal(true)
        },
        async save() {
            const tabs = useTabsStore()
            const schema = useSchemaStore()
            if (!tabs.selectedTab?.unsaved) return
            await schema.saveEntireSchemaData()
        },
        importSchema() {
            const auth = useAuthStore()
            if (auth.planLimitReached) {
                return schema.saveSchema()
            }
            this.toggleImportSqlModal(true)
        },
        table() {
            const tabs = useTabsStore()
            this.createNewTable(tabs.selectedTab.canvas.newItemPosition('table'))
        },
        note() {
            const tabs = useTabsStore()
            this.createNewNote(tabs.selectedTab.canvas.newItemPosition('note'))
        },
        async toggleExportSql() {
            const tabs = useTabsStore()
            const schema = useSchemaStore()
            const tab = tabs.selectedTab
            if (!tab.schema?.schema_data?.tables?.length) {
                return await useConfirm('Empty Schema', 'Please add some tables to export schema', {
                    okOnly: true,
                    okTitle: 'Got it'
                })
            }
            if (!await schema.checkLimit()) return
            this.exportAi = true
        },
        showKeyboardShortcuts() {
            this.keyBoardShortcuts = true
        },
        toggleMinimap() {
            const settings = useSettingsStore()
            settings.updateSettings({ key: 'minimap', value: !settings.settings.minimap })
        },
        toggleSidebar() {
            const settings = useSettingsStore()
            settings.updateSettings({ key: 'sidebar', value: !settings.settings.sidebar })
        },
        toggleGrid() {
            const settings = useSettingsStore()
            settings.updateSettings({ key: 'grid', value: !settings.settings.grid })
        },
        toggleMarkup() {
            const settings = useSettingsStore()
            settings.updateSettings({ key: 'dbml', value: !settings.settings.dbml })
        },
        lineType() {
            this.toggleLineTypeModal(true)
        },
        showTutorial() {
            const settings = useSettingsStore()
            settings.updateSettings({ key: 'sidebar', value: true })
            settings.updateSettings({ key: 'dbml', value: true })
            settings.updateSettings({ key: 'showTutorial', value: true })
            settings.updateSettings({ key: 'minimap', value: true })
        },
        properties() {
            this.toggleSchemaPropertiesModal(true)
        },
        async sortItems() {
            const schema = useSchemaStore()
            const tabs = useTabsStore()
            if (!tabs.selectedTab.schema.schema_data.tables.concat(tabs.selectedTab.schema.schema_data.notes).length) return
            const confirm = await useConfirm('Auto Sort', 'This will automatically re-arrange all the tables and notes in the current project. You can undo it from the actions toolbar at the top if you do not like the new arrangement.', {
                okTitle: 'Sort Project',
                cancelTitle: 'Cancel'
            })
            if (confirm)
                schema.sortItems()
        },

        async handleMenuItemClick(menuItem) {
            const auth = useAuthStore()
            const tabs = useTabsStore()
            const settings = useSettingsStore()
            const schema = useSchemaStore()
            const versions = useVersionsStore()

            if (versions.isPreviewing) return
            if (menuItem.disabled) return

            if (menuItem.loginNeeded && !auth.user) {
                this.toggleLoginModal(true)
                return
            }

            if (this.embedded && !['sortItems', 'fitToScreen', 'fitWidth'].includes(menuItem.id)) return

            if (auth.planLimitReached) return

            let tab = tabs.selectedTab
            let downloader = new CanvasDownloader(tab)

            switch (menuItem.id) {
                case 'history':
                    this.showHistory()
                    break;
                case 'undo':
                    this.undo()
                    break;
                case 'select-all':
                    this.selectAll()
                    break;
                case 'select-none':
                    this.selectNone()
                    break;
                case 'redo':
                    this.redo()
                    break;
                case 'new':
                    this.newSchema()
                    break;
                case 'loadSchema':
                    this.loadSchema()
                    break;
                case 'schemaProperties':
                    this.properties()
                    break;
                case 'loadTemplate':
                    this.loadTemplate()
                    break;
                case 'table':
                    this.table()
                    break;
                case 'note':
                    this.note()
                    break;
                case 'save':
                    await this.save()
                    break;
                case 'saveAs':
                    if (auth.planLimitReached) {
                        return schema.saveSchema()
                    }
                    this.toggleSchemaSaveAsModal(true)
                    break;
                case 'feedBack':
                    this.toggleFeedbackModal(true)
                    break;
                case 'import':
                    this.importSchema()
                    break;
                case 'sortItems':
                    this.sortItems()
                    break;
                case 'lineType':
                    this.lineType()
                    break;
                case 'signup':
                    router.push('/register')
                    break;
                case 'login':
                    router.push('/login')
                    break;
                case 'logout':
                    auth.logout()
                    break;
                case 'dashboard':
                    router.push('/dashboard')
                    break;
                case 'index':
                    this.indexModal = true
                    break;
                case 'keyboardShortcuts':
                    this.showKeyboardShortcuts()
                    break;
                case 'sql':
                    await this.toggleExportSql()
                    break;
                // case 'ai':
                //     if (!await schema.checkLimit()) return
                //     this.exportAi = true
                //     break;
                case 'image':
                    if (!await schema.checkLimit()) return
                    this.exportImage = true
                    downloader.downloadImage({
                        name: tabHelper.getSnakeCaseName(tab),
                        bg: cssVar('--bg')
                    }).then(e => {
                        this.exportImage = false
                    })
                    break;
                case 'pdf':
                    if (!await schema.checkLimit()) return
                    this.exportPdf = true
                    downloader.downloadPdf({
                        name: tabHelper.getSnakeCaseName(tab),
                        bg: cssVar('--bg')
                    }).then(e => {
                        this.exportPdf = false
                    })
                    break;
                case 'changes':
                    window.open("https://www.erdlab.io/changelog/", "_blank");
                    break;
                case 'documentation':
                    this.toggleDocumentationModal(true)
                    break;
                case 'share':
                    if (auth.planLimitReached) {
                        return schema.saveSchema()
                    }
                    this.toggleShareModal(true)
                    break;
                case 'shortLink':
                    if (auth.planLimitReached) {
                        return schema.saveSchema()
                    }
                    this.getShortLink(tabs.selectedTab?.schema.slug)
                    this.toggleSharableLinkModal(true)
                    break;
                case 'embed':
                    if (auth.planLimitReached) {
                        return schema.saveSchema()
                    }
                    if (!tabs.selectedTab.schema.slug) {
                        notify.error(i18n.global.t('alerts.schema_not_exist'), i18n.global.t('alerts.save_schema_for_link'))
                        return
                    }
                    this.getShortLink(tabs.selectedTab?.schema.slug)
                    this.toggleEmbedModal(true)
                    break;
                case 'about':
                    window.open("https://www.erdlab.io/about/", "_blank")
                    break;
                case 'options':
                    this.toggleOptionModal(true)
                    break;
                case 'show_tutorial':
                    this.showTutorial()
                    break;
                case 'toggle_sidebar':
                    this.toggleSidebar()
                    break;
                case 'toggle_minimap':
                    this.toggleMinimap()
                    break;
                case 'toggle_grid':
                    this.toggleGrid()
                    break;
                case 'simple_view':
                    tab.settings.view = 'simple_view'
                    tabs.saveTabsInStorage()
                    break;
                case 'detailed_view':
                    tab.settings.view = 'detailed_view'
                    tabs.saveTabsInStorage()
                    break;
                case 'physical_relationships_view':
                    tab.settings.view = 'physical_relationships_view'
                    tabs.saveTabsInStorage()
                    break;
                case 'logical_relationships_view':
                    tab.settings.view = 'logical_relationships_view'
                    tabs.saveTabsInStorage()
                    break;
                case 'presentation_view':
                    this.togglePresentationView()
                    break;
                case 'settings':
                    settings.showModal = !settings.showModal
                    break;
                case 'fitToScreen':
                    tabs.selectedTab.canvas.fitToScreen()
                    break;
                case 'fitWidth':
                    tabs.selectedTab.canvas.fitWidth()
                    break;
                default:
                    console.log('Unhandled menu item: ', menuItem);
            }

            setTimeout(() => this.setCurrentMenu(null), 50)
        },
        handleKeyboardShortcuts() {
            const tabs = useTabsStore()
            const settings = useSettingsStore()

            function findMenuItem(menu, shortcut) {
                for (let i = 0; i < menu.length; i++) {
                    let m = menu[i]
                    for (let j = 0; j < m.subMenuItems.length; j++) {
                        let item = m.subMenuItems[j]
                        if (item.shortCutKey === shortcut) {
                            return item
                        }
                    }
                }
                return null
            }

            document.addEventListener('keydown', e => {
                if (e.code === 'Escape') {
                    tabs.selectedTab.canvas.selectedItems.items = []
                    tabs.selectedTab.canvas.selectedObject = null
                    this.presentation_view = false
                    tabs.selectedTab.canvas.setSelectedLogicalTable(null)
                }

                if (e.code === 'Delete' && tabs.selectedTab.canvas.selectedItems.items.length) {
                    tabs.deleteSelectedItems()
                }

                if (!e.metaKey && !e.ctrlKey && !e.altKey) return
                if (!settings.settings.enableShortcuts) return

                let shortKey = ''
                if (isMacOs() && e.metaKey) shortKey = '⌘'
                else if (e.ctrlKey) shortKey = 'Ctrl'
                else if (e.altKey) shortKey = isMacOs() ? '⌥' : 'Alt'

                if (!shortKey) return

                let key = e.code.replace(/Key|Digit/, '')
                let shortcut = `${shortKey}+${key}`
                let menuItem = findMenuItem(this.menu, shortcut)

                if (!menuItem) return

                const selectedItems = tabs.selectedTab?.canvas?.selectedItems || {items: []}
                if (menuItem.id === 'select-all' && selectedItems.items.length > 0) {
                    selectedItems.clear()
                    e.preventDefault()
                    return false
                }

                e.preventDefault()
                this.handleMenuItemClick(menuItem)
            })
        },
        generateSql(payload) {
            const tabs = useTabsStore()
            this.generatingSql = true
            this.generateSchemaReq.url = `schemas/${tabs.selectedTab?.schema.id}/generate_sql.json`;
            let data = {
                'schema_data': (payload && payload.db_type) ? JSON.stringify({
                    ...tabs.selectedTab?.schema.schema_data,
                    db: payload.db_type
                }) : JSON.stringify(tabs.selectedTab?.schema.schema_data),
                'script_type': payload.script_type || 'create'
            }
            this.generateSchemaReq.send({
                body: JSON.stringify(data)
            }).then(async (res) => {
                let filename = tabHelper.getSnakeCaseName(tabs.selectedTab) + '.sql'
                FileHelper.downloadAsFile([res.sql], filename)
            }).finally(() => {
                this.generatingSql = false
                this.exportSql = false
            });
        },
        exportWithAi(payload) {
            const tabs = useTabsStore()
            const downloads = useDownloadsStore()
            this.exportAiLoading = true
            let data = {
                'schema_data': (payload && payload.db_type) ? JSON.stringify({
                    ...tabs.selectedTab?.schema.schema_data,
                    db: payload.db_type
                }) : JSON.stringify(tabs.selectedTab?.schema.schema_data),
                'script_type': payload.script_type || 'create'
            }
            this.exportWithAiReq.url = `schemas/${tabs.selectedTab?.schema.id}/generator_by_ai`;
            this.exportWithAiReq.send({
                body: JSON.stringify(data)
            })
                .then(async (data) => {
                    this.exportAiLoading = false
                    this.exportAi = false
                    const confirm = await useConfirm("SQL Ready!", "Please visit the Download Section of your account Dashboard to download.", {
                        okTitle: "Open Downloads in new tab",
                        cancelTitle: "Later"
                    })
                    if (confirm) {
                        window.open(`${env.host}/downloads`, '_blank')
                        if (data.file_url) {
                            downloads.downloadExportedSql(data)
                        }
                    }
                })
                .catch(() => this.exportAiLoading = false)
        },
        determineMetaData(tab) {
            let limitReached = tab.schema.tables_count >= CRITICAL_TABLE_LIMIT || tab.schema.fields_count >= CRITICAL_FIELD_LIMIT
            this.toggleCriticalTableLimitReachedModal(limitReached)
            if (limitReached)
                tab.settings.view = 'simple_view'
        },
        async getShortLink(slug) {
            this.getShortUrlReq.url = `schemas/${slug}/short_url`
            return await this.getShortUrlReq.send()
                .then(res => {
                    return res.sUrl
                })
        },
        async togglePresentationView() {
            let isFullScreen = !!document.fullscreenElement
            if (isFullScreen) {
                // exit fullscreen
                if (document.exitFullscreen) {
                    await document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    await document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    await document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    await document.msExitFullscreen();
                }
            } else {
                // enter fullscreen
                var elem = document.documentElement;
                if (elem.requestFullscreen) {
                    await elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    await elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    await elem.msRequestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    await elem.mozRequestFullScreen();
                }
            }
        },
        clearLocalStorage() {
            // clear local storage
            let appVersion = localStorage.getItem('appVersion')
            if (!appVersion || appVersion !== env.appVersion) {
                localStorage.clear()
                window.location.reload()
            }
            localStorage.setItem('appVersion', env.appVersion)
        }
    }
})
