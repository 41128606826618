<script lang="ts" setup>

</script>

<template>
    <img src="@/assets/images/erdlab-logo.png" alt="Logo.png" style="width: 30%"/>
</template>

<style scoped lang="scss">
</style>
