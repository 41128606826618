<script setup>
import UModal from "nvd-u/components/UModal.vue";
import USwitch from "nvd-u/components/USwitch.vue";
import UColorPicker from "nvd-u/components/UColorPicker.vue";
import DarkModeSettings from "../SchemaView/schema-settings/DarkModeSettings.vue";
import SettingsItem from "../SchemaView/schema-settings/SettingsItem.vue";
import LanguageSelector from "../../components/common/LanguageSelector.vue";
import ThemeSelector from "../../components/common/ThemeSelector.vue";
import { useRoute } from "vue-router";
import { LINE_TYPES } from "src/data/constants";
import { SchemaToDbml } from "../../components/code-editor/schemaToDbml";
import { useSettingsStore } from "../../stores/settings.store";
import { useTabsStore } from "../../stores/tabs.store";
import { isMacOs } from "../../helpers/osHelper";
import MinimapIcon from '../SchemaView/schema-settings/MinimapIcon.vue';
import SidebarIcon from 'nvd-u/icons/PageLayoutSidebarRight.vue'
import CommentIcon from 'nvd-u/icons/Comment.vue'
import CodeBracesIcon from 'nvd-u/icons/CodeBraces.vue'
import DotsGridIcon from 'nvd-u/icons/DotsGrid.vue'
import SitemapIcon from 'nvd-u/icons/Sitemap.vue'
import TranslateIcon from 'nvd-u/icons/Translate.vue';
import ThemeLightDarkIcon from "nvd-u/icons/ThemeLightDark.vue";
import KeyboardIcon from "nvd-u/icons/Keyboard.vue";


const route = useRoute()
const settings = useSettingsStore()
const tab = useTabsStore()

function updateDbml() {
    new SchemaToDbml(tab.selectedTab).convert()
}
</script>

<template>
    <UModal
        class="text-small"
        :title="$t('menu.settings')"
        no-footer
        v-model="settings.showModal">
        <DarkModeSettings/>
        <SettingsItem>
            <TranslateIcon/>
            <div class="flex-grow-1">{{ $t('dialog.options_language') }}</div>
            <LanguageSelector/>
        </SettingsItem>
        <div v-if="route.name  === 'app'">
            <SettingsItem>
                <KeyboardIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.enable_shortcuts') }}
                </div>
                <USwitch v-model="settings.settings.enableShortcuts"/>
            </SettingsItem>
            <SettingsItem>
                <ThemeLightDarkIcon/>
                <ThemeSelector class="d-flex flex-grow-1"/>
            </SettingsItem>
            <SettingsItem>
                <CodeBracesIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.allow_table_hex') }}
                    <div class="text-extra-small mt-2">
                        {{ $t('dialog.allow_table_hex_description') }}
                    </div>
                </div>
                <USwitch v-model="settings.settings.allowTableHexCode" @update:modelValue="updateDbml"/>
            </SettingsItem>
            <SettingsItem>
                <CodeBracesIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.allow_field_hex') }}
                    <div class="text-extra-small mt-2">
                        {{ $t('dialog.allow_field_hex_description') }}
                    </div>
                </div>
                <USwitch v-model="settings.settings.allowFieldHexCode" @update:modelValue="updateDbml"/>
            </SettingsItem>
            <SettingsItem>
                <MinimapIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.show_minimap') }}
                    <div class="text-extra-small mt-2">
                        {{
                            $t('dialog.show_minimap_description', { code: (isMacOs() ? "⌥" : "Alt") + "+M" })
                        }}
                    </div>
                </div>
                <USwitch v-model="settings.settings.minimap"/>
            </SettingsItem>
            <SettingsItem>
                <SidebarIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.show_sidebar') }}
                    <div class="text-extra-small mt-2">
                        {{ $t('dialog.show_sidebar_description', { code: (isMacOs() ? "⌥" : "Alt") + "+B" }) }}
                    </div>
                </div>
                <USwitch v-model="settings.settings.sidebar"/>
            </SettingsItem>
            <SettingsItem>
                <CodeBracesIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.show_markup') }}
                </div>
                <USwitch v-model="settings.settings.dbml"/>
            </SettingsItem>
            <SettingsItem>
                <DotsGridIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.show_grid') }}
                    <div class="text-extra-small mt-2">
                        {{ $t('dialog.show_grid_description', { code: (isMacOs() ? "⌥" : "Alt") + "+G" }) }}
                    </div>
                </div>
                <USwitch v-model="settings.settings.grid"/>
            </SettingsItem>
<!--            <SettingsItem>-->
<!--                <CommentIcon/>-->
<!--                <div class="flex-grow-1">-->
<!--                    {{ $t('dialog.show_feedback_fab') }}-->
<!--                </div>-->
<!--                <USwitch v-model="settings.settings.feedback"/>-->
<!--            </SettingsItem>-->
            <SettingsItem>
                <SitemapIcon/>
                <div class="flex-grow-1">
                    {{ $t('dialog.choose_preferred_line_type') }}
                </div>
                <div class="d-flex flex-grow-1 flex-wrap" v-if="tab.selectedTab?.canvas?.settings">
                    <label class="my-2 mx-3 d-flex align-items-center" v-for="(lineType, key) in LINE_TYPES" :key="key">
                        <input
                            class="mr-1"
                            type="radio"
                            :value="lineType"
                            @input="e => tab.selectedTab.canvas.updateSettings('lineType', e.target.value)"
                            v-model="tab.selectedTab.canvas.settings.lineType">
                        {{ $t(`dialog.${lineType}`) }}
                    </label>
                </div>
            </SettingsItem>
            <SettingsItem>
                <SitemapIcon/>
                <div class="flex-grow-1" v-if="tab.selectedTab?.canvas?.settings">
                    {{ $t('dialog.options_line_colors') }}
                    <UColorPicker
                        class="mx-5"
                        :model-value="tab.selectedTab.canvas.settings.lineColor"
                        @update:model-value="color => tab.selectedTab.canvas.updateSettings('lineColor', color)"
                    />
                </div>
            </SettingsItem>
        </div>
    </UModal>
</template>
