import { FetchRequest } from "../helpers/fetch-request";
import { useStorage } from 'nvd-use-storage'
import { TABS_KEY, TEAM_MEMBERS_KEY, TOKEN_KEY, USER_KEY } from "@/data/constants";
import { env } from "@/env";
import i18n from "@/locales/translations";
import { useMenuStore } from "@/stores/menu.store";
import { defineStore } from "pinia";
import { useNotify } from "nvd-u/composables/Notifiy";
import { useConfirm } from "nvd-u/composables/Confirm";
import { Storage } from "nvd-js-helpers/storage-helper";
import { useBillingStore } from "@/stores/billing.store";
import router from "@/router/routes";

const notify = useNotify()

export const useAuthStore = defineStore('auth', {
    state: () => ({
        loginReq: new FetchRequest('login', 'POST'),
        registerReq: new FetchRequest('register', 'POST'),
        forgotPasswordReq: new FetchRequest('password/forgot', 'POST'),
        logoutReq: new FetchRequest('logout', 'DELETE'),
        user: useStorage(USER_KEY),
        authToken: useStorage(TOKEN_KEY),
        closeAccountReq: new FetchRequest('close-account', 'DELETE'),
        updateUserReq: new FetchRequest('update_account', 'POST'),
        checkLoginReq: new FetchRequest('check_login', 'POST'),
        updateProfileReq: new FetchRequest('update_profile', 'POST'),
        planLimitReached: false,
        emailVerificationReq: new FetchRequest('resend_confirmation', 'POST'),
        teamMembers: useStorage(TEAM_MEMBERS_KEY, [])
    }),
    getters: {
        isLoggedIn: state => !!state.authToken && !!state.user,
        isNewUser: state => Math.max(state.user.register_at, 1681412400) === state.user.register_at,
        maxTeamMembers() {
            return this.user?.membersLimit + (this.user?.extra_members_count || 0)
        },
        isOnTrail() {
        },
    },
    actions: {
        // mutations
        setAuthToken(token) {
            this.authToken = token;
        },
        setUser(user) {
            this.user = user
            Storage.setObject(USER_KEY, user)
        },

        // actions
        afterLogin(data) {
            const menu = useMenuStore()
            const billing = useBillingStore()
            this.setAuthToken(data.user.oauth)
            this.setUser(data.user)
            this.teamMembers = data.members || []
            menu.updateMenu()
            if (data.is_register) return window.location.href = env.designerUrl + '/guest_template'
            billing.loadBillingInfo()
            return router.replace('/dashboard')
        },
        login(payload) {
            this.loginReq.send({
                body: JSON.stringify({ email: payload.email, password: payload.password })
            }).then(data => {
                notify.success(`Welcome ${data.user.name}!`, 'Successfully logged in')
                if (payload.is_register) data.is_register = true
                this.afterLogin(data)
            })
        },
        register(payload) {
            if (payload.update) {
                delete payload['update']
                return this.updateProfile(payload)
            }
            return this.registerReq.send({
                body: JSON.stringify(payload)
            })
        },
        forgotPassword(payload) {
            return this.forgotPasswordReq.send({
                body: JSON.stringify({ email: payload.email })
            }).then(data => {
                notify.success('Email sent', 'Please check your email to reset password')
            }).catch(data => {
                notify('danger', 'Request failed', 'We could not find your email.')
            })
        },
        logout() {
            this.logoutReq.send().then(res => {
                const menu = useMenuStore()
                this.setAuthToken('')
                this.setUser('')
                localStorage.removeItem(TABS_KEY)
                menu.updateMenu()
                notify.success('Logged out!', 'You have successfully logged out.')
                window.location.href = '/'
            })
        },
        deleteAccount(delete_data = false) {
            this.closeAccountReq.url = `close-account?delete_data=${delete_data}`
            this.closeAccountReq.send()
                .then(res => {
                    notify.success('Your account was deleted successfully.')
                    this.logout()
                })
        },
        async showAppUpgradePopup() {
            if (this.user) {
                const shouldShowBanner = await this.shouldShowBanner()
                if (!shouldShowBanner) return
                let key = 'rejected_old_app_popup'
                let rejected_count = await localStorage.getItem(key)
                const result = await useConfirm(i18n.global.t('Please Note'), i18n.global.t('We have upgraded ERDLab. You will be able to enjoy a lot of new features here. You can go back (not recommended) if you don\'t like it here.'), {
                    cancelTitle: i18n.global.t('Stay'),
                    okTitle: i18n.global.t('Go Back')
                })
                localStorage.setItem(key, rejected_count ? parseInt(rejected_count) + 1 : 1)
                if (result) {
                    window.location.href = 'https://app.erdlab.io'
                }
            }
        },
        async updateUser(payload) {
            const notify = useNotify()
            if (this.updateUserReq.loading) return
            this.updateUserReq.config.data = payload
            return this.updateUserReq.send({
                body: JSON.stringify({
                    name: payload.name,
                    company_name: payload.company_name,
                    password: payload.password
                })
            })
                .then((data) => {
                    if (data.status === 'ok') {
                        notify.success('Profile Updated Successfully')
                        this.reFetchUser()
                    }
                })
        },
        reFetchUser() {
            const billing = useBillingStore()
            if (!this.user || !this.user.oauth) return
            this.checkLoginReq.send({
                body: JSON.stringify({ oauth: this.user.oauth })
            })
                .then(data => {
                    this.setAuthToken(data.user.oauth)
                    this.setUser(data.user)
                    this.teamMembers = data.members || []
                    billing.loadBillingInfo()
                })
        },
        async shouldShowBanner() {
            if (this.isNewUser) return
            let key = 'rejected_old_app_popup'
            let rejected_count = await localStorage.getItem(key)
            return !(rejected_count && parseInt(rejected_count) >= 1);
        },
        updateProfile(payload) {
            this.updateProfileReq.config.body = JSON.stringify(payload)
            return this.updateProfileReq.send()
        },
        async showPlanLimitReachedModal(payload) {
            const billing = useBillingStore()
            if (this.user)
                this.planLimitReached = payload
            if (payload) {
                billing.loadBillingInfo()
            }
        },
        verifyEmail() {
            this.emailVerificationReq.send()
                .then(() => {
                    notify.success('Please check your email', 'We sent you a confirmation email.', {
                        permanent: true
                    })
                })
        }
    }
})
