export const env = {
    socketIoServer: 'erdlab.org',
    apiUrl: 'https://erdlab.org/api/v2/',
    appUrl: 'https://www.erdlab.io',
    designerUrl: 'https://app.erdlab.io/designer/schema',
    host: 'https://app.erdlab.io',
    appName: 'ERD Lab',
    appEmail: 'support@erdlab.io',
    salt: 'salt.duh!',
    defaultLocale: 'en',
    localeOptions: [
        {id: 'en', name: 'English', direction: 'ltr'},
        {id: 'es', name: 'Español', direction: 'ltr'},
        {id: 'bn', name: 'Bengali', direction: 'ltr'},
        {id: 'ca', name: 'Catalan', direction: 'ltr'},
        {id: 'cs', name: 'Czech', direction: 'ltr'},
        {id: 'da', name: 'Danish', direction: 'ltr'},
        {id: 'de', name: 'German', direction: 'ltr'},
        {id: 'eo', name: 'Esperanto', direction: 'ltr'},
        {id: 'fa', name: 'Persian', direction: 'ltr'},
        {id: 'fi', name: 'Finnish', direction: 'ltr'},
        {id: 'fr', name: 'French', direction: 'ltr'},
        {id: 'gr', name: 'Greek', direction: 'ltr'},
        {id: 'gr', name: 'Indonesian', direction: 'ltr'},
        {id: 'it', name: 'Italian', direction: 'ltr'},
        {id: 'ja', name: 'Japanese', direction: 'ltr'},
        {id: 'kn', name: 'Kannada', direction: 'ltr'},
        {id: 'nb_no', name: 'Norwegian Bokmål', direction: 'ltr'},
        {id: 'nl', name: 'Dutch', direction: 'ltr'},
        {id: 'pl', name: 'Polish', direction: 'ltr'},
        {id: 'pt_br', name: 'Portuguese', direction: 'ltr'},
        {id: 'ro', name: 'Romanian', direction: 'ltr'},
        {id: 'ru', name: 'Russian', direction: 'ltr'},
        {id: 'tr', name: 'Turkish', direction: 'ltr'},
        {id: 'ua', name: 'Ukrainian', direction: 'ltr'},
        {id: 'uz', name: 'Uzbek', direction: 'ltr'},
        {id: 'vi', name: 'Vietnamese', direction: 'ltr'},
        {id: 'zh', name: 'Chinese', direction: 'ltr'},
    ],
    dbImportFromLinkSupported: false,
    g_id: '405529418010-3ttdh59oir17hf7df6hnm0drgtrgvth0.apps.googleusercontent.com',
    appVersion: '1.0'
};